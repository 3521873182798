// extracted by mini-css-extract-plugin
export var menu = "style-module--menu--L-PyN";
export var menuLinks = "style-module--menuLinks--1iwIo";
export var menuData = "style-module--menuData--1-Ahs";
export var menuDataItem = "style-module--menuDataItem--2wuqT";
export var menuDataItemTitle = "style-module--menuDataItemTitle--1iiAI";
export var menuDataItemAmount = "style-module--menuDataItemAmount--23IQk";
export var menuFooter = "style-module--menuFooter--2WNVM";
export var menuFooterCardano = "style-module--menuFooterCardano--1cXoA";
export var menuFooterLogo = "style-module--menuFooterLogo--JbElz";
export var menuBtn = "style-module--menuBtn--1fxIM";
export var menuBtnActive = "style-module--menuBtnActive--2VT1s";